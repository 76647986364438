import React, { useEffect, useState } from 'react';
import '../css/PostPage.css';
import avatar1 from '../images/Furloop_Favicon.png';
import { useParams } from 'react-router-dom';

const PostPage = () => {
  const { slug } = useParams();
  const [postData, setPostData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPostData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://cms.furloop.io/api/articles?filters[slug][$eq]=${slug}&populate=*`
        );
        const data = await response.json();
        console.log("Fetched Data:", data);
        if (data && data.data && data.data.length > 0) {
          setPostData(data.data[0]);
        } else {
          setPostData(null);
        }
      } catch (error) {
        console.error("Error fetching post data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPostData();
  }, [slug]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!postData) {
    return <p>Post not found!</p>;
  }

  const { title, description, cover, author, category, Test } = postData;
  const imageUrl = `https://cms.furloop.io${cover?.formats?.thumbnail?.url}`;

  const formattedDate = author?.publishedAt
    ? new Date(author.publishedAt).toLocaleDateString("en-US", {
        day: "numeric",
        month: "long",
        year: "numeric",
      })
    : "Unknown Date";

  return (
    <div className="post-page">
      {category?.name && <div className="blog-category-links">{category.name}</div>}
      <h1 className="post-title">{title}</h1>
      {imageUrl && (
        <img src={imageUrl} alt={cover?.alternativeText || title} className="post-image" />
      )}

      <div className='card-body'>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div>
            <h3 className="single-blogcard-date">Posted on | {formattedDate}</h3>
          </div>
        </div>

        <div style={{ display: "flex", margin: "30px 0px 50px 0px" }} className="d-none d-md-flex">
          <div style={{ width: "30%", display: "flex", alignItems: "center" }}>
            <img src={avatar1} alt="logo" width="50px" height="50px" />
            <div style={{ marginLeft: "15px" }}>
              <p style={{ margin: "0px", color: "#818181", fontWeight: 500, fontSize: "1rem", textAlign: "left" }}>Written by</p>
              <p style={{ marginTop: "0px", color: "#6941C6", fontWeight: 600 }}>
                <span>{author?.name || "Unknown Author"}</span>
              </p>
            </div>
          </div>

          <p className="single-blogcard-text" style={{ color: "#000000", marginLeft: "20px", textAlign: "left" }}>
            {description}
          </p>
        </div>
      </div>

      {/* Render the 'Test' field content dynamically */}
      <div className="post-content">
        <div className="text-content" dangerouslySetInnerHTML={{ __html: Test }} />
      </div>

      <hr className="d-md-none" style={{ color: "#D9D9D9", width: "98%" }} />

      <div className="d-flex justify-content-between align-items-center d-md-none" style={{ whiteSpace: "nowrap" }}>
        <div style={{ width: "40%", display: "flex", alignItems: "center" }}>
          <img src={avatar1} alt="logo" width="20px" height="20px" />
          <div style={{ marginLeft: "10px", paddingTop: "3px" }}>
            <p style={{ margin: "0px", color: "#818181", fontWeight: 500, fontSize: "1rem" }}>
              Written by <span style={{ color: "#6941C6", fontWeight: 600 }}>{author?.name || "Unknown Author"}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostPage;
