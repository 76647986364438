import React from "react";
import '../css/PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <section className="main-section-header">
        <div className="main-container">
          <div className="subheader cta-color">Privacy Policy</div>
        </div>
      </section>
      <section className="privacy-policy-content">
        <div className="main-container">
          {/* <h2>Privacy Policy for Furloop</h2> */}
          <h3><strong>Effective Date:</strong> 1/1/2025</h3><br/>
          <p>
          Furloop ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy
          explains how we collect, use, disclose, and safeguard your information when you use our
          services, including our website and SaaS platform (collectively, the "Service").
          </p>

          <h3>1. Information We Collect</h3>
          <p><strong>1.1 Information You Provide to Us</strong></p><br/>
          <ul>
            <li><strong>Account Information:</strong> When you create an account, we collect your name, email address, business details, and password.</li>
            <li><strong>Payment Information:</strong> For billing purposes, we collect payment details, including credit card or other payment method information.</li>
            <li><strong>Customer Data:</strong>: If you use our trade-in or recommerce features, you may upload customer or device data. This data remains your property and is processed only as needed to provide the Service.</li>
          </ul>
          <p><strong>1.2 Information We Collect Automatically</strong></p><br/>
          <ul>
            <li><strong>Usage Data:</strong> We collect information about how you interact with the Service, such as IP address, browser type, pages viewed, and actions taken.</li>
            <li><strong>Device Data:</strong> We collect information about the devices you use to access the Service, including operating system and device identifiers</li>
          </ul>
          <p><strong>1.3  Cookies and Tracking Technologies</strong></p><br/>
          <p>
          We use cookies and similar technologies to enhance your experience, analyze usage, and
          provide personalized content. You can control cookie preferences through your browser
          settings.

          </p>

          <h3>2. How We Use Your Information</h3>
           <p>We use your information for the following purposes: </p><br/>
          <ul>
            <li><strong>To Provide the Service:</strong>: Including account creation, billing, and technical support.</li>
            <li><strong>To Improve the Service:</strong> Analyzing usage trends to enhance functionality and user experience.</li>
            <li><strong>To Communicate with You:</strong>: Sending updates, marketing messages, or responding to inquiries.</li>
            <li><strong>To Ensure Security:</strong>Detecting and preventing fraud, unauthorized access, or other malicious activities. </li>
          </ul>

          <h3>3.  How We Share Your Information </h3>
          <p>We do not sell your personal information. We may share your information as follows:</p><br/>
          <ul>
            <li><strong>With Service Providers:</strong> Trusted third-party vendors who help us operate the Service, such as payment processors and cloud hosting providers.</li>
            <li><strong>As Required by Law:</strong>  To comply with legal obligations or respond to lawful requests from government authorities.</li>
            <li><strong>With Your Consent:</strong> When you explicitly agree to share your information. </li>
          </ul>

          <h3>4. Data Retention</h3>
          <p>
          We retain your information for as long as necessary to provide the Service, comply with legal
obligations, resolve disputes, and enforce agreements. Customer data is deleted upon
termination of your account or as otherwise instructed by you.

          </p>

          <h3>5. Your Rights</h3>
          <p>Depending on your location, you may have the following rights:
          </p><br/>
          <ul>
            <li><strong>Access and Correction:</strong> Request access to or correction of your personal information. </li>
            <li><strong>Deletion:</strong> Request deletion of your personal information.</li>
            <li><strong>Data Portability:</strong> Request a copy of your data in a structured, machine-readable
            format.</li>
            <li><strong>Opt-Out of Marketing:</strong> Unsubscribe from marketing communications at any time</li>
          </ul>
          <p>To exercise these rights, please contact us at [Insert Contact Email].
          </p>

          <h3>6. Security</h3>
          <p>
          We implement reasonable technical and organizational measures to protect your information
from unauthorized access, use, or disclosure. However, no system is completely secure, and
we cannot guarantee absolute security.
          </p>

          <h3>7. International Data Transfers</h3>
          <p>
          If you are located outside [Insert Country], your information may be transferred to and
processed in [Insert Country], where data protection laws may differ from your jurisdiction.

          </p>

          <h3>8. Third-Party Links</h3>
          <p>
          The Service may contain links to third-party websites or services. We are not responsible for
the privacy practices of these third parties, and we encourage you to review their privacy
policies.

          </p>

          <h3>9. Changes to This Privacy Policy</h3>
          <p>
          We may update this Privacy Policy from time to time. We will notify you of significant
          changes by posting the updated policy on our website and updating the effective date. 
          </p>

          <h3>10. Contact Us</h3>
          <p>If you have any questions about this Privacy Policy or our data practices, please contact us
          at:</p><br/>
          <p>
            <strong>Furloop</strong> 
            <strong>Email:</strong> help@furloop.io<br />
            <strong>Address:</strong> 2/10 Akuna Drive, Williamstown North, Victoria 3016
          </p>
        </div>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
