import React, { useState } from 'react';
import { useEffect } from 'react';
import '../css/webflow.css';
import '../css/normalize.css';
import '../css/manmade-cycle-main.webflow.css';
import image1 from '../images/Group-1000002493.svg';
import image2 from '../images/Group-1000002495.svg';
import { Link } from 'react-router-dom';

const PriceSection = () => {
  const [activeTab, setActiveTab] = useState('yearly');
  const [s_plan, s_setPlan] = useState([]);
  const [s_feature, s_setFeature] = useState([]); 
  const [error, setError] = useState('');

  useEffect(() => {
    // Fetch the data from the PHP API
    const fetchData = async () => {
      try {
        const response = await fetch('https://app.furloop.io/api/reseller/subscription_plan.php');
        const result = await response.json();

        if (result.status === 'success') {

          s_setPlan(result.s_plan_data);
          s_setFeature(result.s_features_data);
         
        } else {
          setError(result.message);  
        }
      } catch (error) {
        setError('Error fetching data');
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);



  // Function to get features for specific Monthly subscription plans
  const getFilteredMonthlyPlans = (plans = [], features = []) => {
   
    const filteredPlans = plans.filter(plan => 
      (plan.s_name === "Free Plan" && plan.s_type === "monthly") ||
      (plan.s_name === "Basic" && plan.s_type === "monthly") ||
      (plan.s_name === "Plus" && plan.s_type === "monthly") ||
      (plan.s_name === "Enterprise" && plan.s_type === "monthly")
    );

    return filteredPlans.map(plan => {
      const planFeatures = features.filter(feature => feature.plan_id === plan.id);
      return {
        ...plan,
        features: planFeatures
      };
    });
  };

  const getMonthlyPlans = getFilteredMonthlyPlans(s_plan, s_feature);

// Function to get features for specific Yearly subscription plans
const getFilteredYearlyPlans = (plans = [], features = []) => {
   
  const filteredPlans = plans.filter(plan => 
    (plan.s_name === "Free Plan" && plan.s_type === "yearly") ||
    (plan.s_name === "Basic" && plan.s_type === "yearly") ||
    (plan.s_name === "Plus" && plan.s_type === "yearly") ||
    (plan.s_name === "Enterprise" && plan.s_type === "yearly")
  );

  return filteredPlans.map(plan => {
    const planFeatures = features.filter(feature => feature.plan_id === plan.id);
    return {
      ...plan,
      features: planFeatures
    };
  });
};

const getYearlyPlans = getFilteredYearlyPlans(s_plan, s_feature);



 //Conditions for Monthly Plan
  const freeMonthlyPlan = getMonthlyPlans?.filter(
    plan => plan.s_name === 'Free Plan' && plan.s_type === 'monthly'
  ) || [];

  const basicMonthlyPlan = getMonthlyPlans?.filter(
    plan => plan.s_name === 'Basic' && plan.s_type === 'monthly'
  ) || [];

  const plusMonthlyPlan = getMonthlyPlans?.filter(
    plan => plan.s_name === 'Plus' && plan.s_type === 'monthly'
  ) || [];

  const enterpriseMonthlyPlan = getMonthlyPlans?.filter(
    plan => plan.s_name === 'Enterprise' && plan.s_type === 'monthly'
  ) || [];


  //Condition for Yearly Plan
  const freeYearlyPlan = getYearlyPlans?.filter(
    plan => plan.s_name === 'Free Plan' && plan.s_type === 'yearly'
  ) || [];

  const basicYearlyPlan = getYearlyPlans?.filter(
    plan => plan.s_name === 'Basic' && plan.s_type === 'yearly'
  ) || [];

  const plusYearlyPlan = getYearlyPlans?.filter(
    plan => plan.s_name === 'Plus' && plan.s_type === 'yearly'
  ) || [];

  const enterpriseYearlyPlan = getYearlyPlans?.filter(
    plan => plan.s_name === 'Enterprise' && plan.s_type === 'yearly'
  ) || [];

  
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div className="content">
        <div className="container">
          <div className="content-wrap-2">
            <div className="content-heading div-block">
              <h1 className="heading-pricing">Choose a plan and start your trade in business today</h1>
            </div>
            <div className="content-text">
              <h1 className="heading-2-pricing">30-Day <span className="gradeint-header-pricing">Free Trial</span><br /></h1>
            </div>
            <div
              data-current="Tab 1"
              data-easing="ease"
              data-duration-in="300"
              data-duration-out="100"
              className="tabs w-tabs"
            >
              <div className="tabs-menu-2 w-tab-menu" id="pricing">
                <a data-w-tab="Tab 1" className={`tab-link-monthly w-inline-block w-tab-link ${activeTab === 'monthly' ? 'w--current' : ''}`}
                  onClick={() => handleTabClick('monthly')}>

                  <div>Pay Monthly</div>
                </a>
                <a data-w-tab="Tab 2" className={`tab-link-yearly w-inline-block w-tab-link ${activeTab === 'yearly' ? 'w--current' : ''}`}
                  onClick={() => handleTabClick('yearly')}>
                  <div>Pay Yearly</div>
                </a>
              </div>
              <div className="tabs-content-2 w-tab-content">
                <div data-w-tab="Tab 1" className={`monthly w-tab-pane ${activeTab === 'monthly' ? 'w--tab-active' : ''}`}>
                  <div className="content-cards">
                    {/* Card 1 */}
                    <div className="card">
                      <div className="card-wrap">
                        <div className="card-top">
                          <div className="card-heading">
                            <h2 className="mb-20">{freeMonthlyPlan[0]?.s_name}</h2>
                            <p className="paragraph-6">{freeMonthlyPlan[0]?.s_description}</p>
                          </div>
                          <div className="card-price">
                            <p className="mb-5"><span className="p-22">${freeMonthlyPlan[0]?.price}</span>/mo</p>
                          </div>
                        </div>
                        <div className="card-bot">
                          {
                            freeMonthlyPlan[0]?.features?.map((featureM) => (
                              <div className="check">
                                  <img loading="lazy" src={image1} alt="" />
                                   <p key={featureM.id}><span className="purple-holder">{featureM.feature_text}</span></p>
                               </div>
                            ))
                          }
                          
                        </div>                     
                           
                      </div>
                        <div class="mb-4">
                              <Link data-ms-price-update="" to="/sign-up-today"  className="button-2 big w-inline-block">
                                  <p>Get Started</p>
                                </Link>
                        </div>
                    </div>
                    {/* Card 2 */}
                    <div className="card">
                      <div className="card-wrap">
                        <div className="card-top">
                          <div className="card-heading">
                            <h2 className="mb-20">{basicMonthlyPlan[0]?.s_name}</h2>
                            <p className="paragraph-6">{basicMonthlyPlan[0]?.s_description}</p>
                          </div>
                          <div className="card-price">
                            <p className="mb-5"><span className="p-22">${basicMonthlyPlan[0]?.price}</span>/mo</p>
                          </div>
                        </div>
                        <div className="card-bot">
                          {
                            basicMonthlyPlan[0]?.features?.map((featureM) => (
                              <div className="check">
                                  <img loading="lazy" src={image1} alt="" />
                                   <p key={featureM.id}><span className="purple-holder">{featureM.feature_text}</span></p>
                               </div>
                            ))
                          }
                          
                        </div>
                        
                      </div>
                      <div class="mb-4">
                        <Link data-ms-price-update="prc_basic-ytuo0uc9"  to="/sign-up-today" className="button-2 big w-inline-block">
                          <p>Get Started</p>
                        </Link>
                        </div>
                    </div>

                    {/* Card 3 */}
                    <div className="card mid">
                      <div className="card-wrap mid">
                        <div className="card-top">
                          <div className="card-heading">
                            <h1 className="heading-2"><span className="gradeint-header-pricing-medium">{plusMonthlyPlan[0]?.s_name}</span><br /></h1>
                            <p className="paragraph-7">{plusMonthlyPlan[0]?.s_description}</p>
                          </div>
                          <div className="card-price">
                            <p className="mb-5"><span className="p-22">${plusMonthlyPlan[0]?.price}</span>/mo</p>
                          </div>
                        </div>
                        <div className="card-bot">
                        {
                            plusMonthlyPlan[0]?.features?.map((featureM) => (
                              <div className="check">
                                  <img loading="lazy" src={image1} alt="" />
                                   <p key={featureM.id}><span className="purple-holder">{featureM.feature_text}</span></p>
                               </div>
                            ))
                          }
                         
                        </div>
                      </div>
                      <div class="mb-4">
                        <Link data-ms-price-update="prc_plus-ltin0qul"  to="/sign-up-today" className="button-2 big w-inline-block">
                          <p>Get Started</p>
                        </Link>
                        </div>
                    </div>

                    {/* Card 4 */}
                    <div className="card">
                      <div className="card-wrap">
                        <div className="card-top">
                          <div className="card-heading">
                            <h2 className="mb-20">{enterpriseMonthlyPlan[0]?.s_name}</h2>
                            <p className="paragraph-8">{enterpriseMonthlyPlan[0]?.s_description}</p>
                          </div>
                          <div className="card-price">
                            <p className="mb-5">
                              {enterpriseMonthlyPlan[0]?.price === null || enterpriseMonthlyPlan[0]?.price === '0' ? (
                               'Contact Sales'
                              ) : (
                              <span className="p-22">${enterpriseMonthlyPlan[0]?.price}</span>
                             )}
                            </p>
                          </div>
                        </div>
                        <div className="card-bot">
                        {
                            enterpriseMonthlyPlan[0]?.features?.map((featureM) => (
                              <div className="check">
                                  <img loading="lazy" src={image1} alt="" />
                                   <p key={featureM.id}><span className="purple-holder">{featureM.feature_text}</span></p>
                               </div>
                            ))
                          }
                         
                        </div>
                      </div>
                      <div class="mb-4">
                        <Link data-ms-price-update="prc_enterprise-bdd04a2"  to="/sign-up-today" className="button-2 big w-inline-block">
                          <p>Get Started</p>
                        </Link>
                        </div>
                    </div>
                  </div>
                </div>

                {/* Tab 2 */}
                <div data-w-tab="Tab 2" className={`yearly w-tab-pane ${activeTab === 'yearly' ? 'w--tab-active' : ''}`}>
                  <div className="content-cards">
                    
                  <div className="card">
                      <div className="card-wrap">
                        <div className="card-top">
                          <div className="card-heading">
                            <h2 className="mb-20">{freeYearlyPlan[0]?.s_name}</h2>
                            <p className="paragraph-10">{freeYearlyPlan[0]?.s_description}</p>
                          </div>
                          <div className="card-price">
                            <p className="mb-5">
                              {/* <span className="strike">${freeMonthlyPlan[0]?.price}/mo</span> */}
                              $<span className="p-22">{freeYearlyPlan[0]?.price}</span>/mo</p>
                          </div>
                        </div>
                        <div className="card-bot">
                        {
                            freeYearlyPlan[0]?.features?.map((feature) => (
                              <div className="check">
                                  <img loading="lazy" src={image1} alt="" />
                                   <p key={feature.id}><span className="purple-holder">{feature.feature_text}</span></p>
                               </div>
                            ))
                          }
                         
                        </div>
                        
                        
                      </div>
                      <div class="mb-4"><Link data-ms-price-update="" to="/sign-up-today"  className="button-2 big w-inline-block">
                          <p>Get Started</p>
                        </Link></div>
                    </div>

                    <div className="card">
                      <div className="card-wrap">
                        <div className="card-top">
                          <div className="card-heading">
                            <h2 className="mb-20">{basicYearlyPlan[0]?.s_name}</h2>
                            <p className="paragraph-10">{basicYearlyPlan[0]?.s_description}</p>
                          </div>
                          <div className="card-price">
                            <p className="mb-5">
                              <span className="strike">${basicMonthlyPlan[0]?.price}/mo</span>
                              <sup>$</sup><span className="p-22">{basicYearlyPlan[0]?.price}</span>/mo</p>
                            <p className="p-12">billed annually at $300</p>
                          </div>
                        </div>
                        <div className="card-bot">
                        {
                            basicYearlyPlan[0]?.features?.map((feature) => (
                              <div className="check">
                                  <img loading="lazy" src={image1} alt="" />
                                   <p key={feature.id}><span className="purple-holder">{feature.feature_text}</span></p>
                               </div>
                            ))
                          }
                         
                        </div>
                        
                        
                      </div>
                      <div class="mb-4"><Link data-ms-price-update="prc_basic-n06e0or8"  to="/sign-up-today" className="button-2 big w-inline-block">
                          <p>Get Started</p>
                        </Link></div>
                    </div>

                    <div className="card mid">
                      <div className="card-wrap mid">
                        <div className="card-top">
                          <div className="card-heading">
                            <h2 className="mb-20">{plusYearlyPlan[0]?.s_name}</h2>
                            <p className="paragraph-11">{plusYearlyPlan[0]?.s_description}</p>
                          </div>
                          <div className="card-price">
                            <p className="mb-5">
                              <span className="strike">${plusMonthlyPlan[0]?.price}/mo</span> <sup>$</sup>
                              <span className="p-22">{plusYearlyPlan[0]?.price}</span>/mo</p>
                            <p className="p-12">billed annually at $624</p>
                          </div>
                        </div>
                        <div className="card-bot">
                        {
                            plusYearlyPlan[0]?.features?.map((feature) => (
                              <div className="check">
                                  <img loading="lazy" src={image1} alt="" />
                                   <p key={feature.id}><span className="purple-holder">{feature.feature_text}</span></p>
                               </div>
                            ))
                          }
                          

                        </div>
                        
                        
                      </div>
                      <div class="mb-4"><Link data-ms-price-update="prc_plus-pb6f0lbu"  to="/sign-up-today" className="button-2 big w-inline-block">
                          <p>Get Started</p>
                        </Link></div>
                    </div>

                    <div className="card">
                      <div className="card-wrap">
                        <div className="card-top">
                          <div className="card-heading">
                            <h2 className="mb-20">{enterpriseYearlyPlan[0]?.s_name}</h2>
                            <p className="paragraph-12">{enterpriseYearlyPlan[0]?.s_description}</p>
                          </div>
                          <div className="card-price">
                            {/* <p className="mb-5"><span className="p-22">Contact</span> Us</p> */}
                            <p className="mb-5">
                              <span className="p-22">
                              {enterpriseYearlyPlan[0]?.price === null || enterpriseYearlyPlan[0]?.price === '0' ? (
                               'Contact Us'
                              ) : (
                               <span >${enterpriseYearlyPlan[0]?.price}</span>
                                )}
                                </span>
                              </p>
                          </div>
                        </div>
                        <div className="card-bot">
                        {
                            enterpriseYearlyPlan[0]?.features?.map((feature) => (
                              <div className="check">
                                  <img loading="lazy" src={image1} alt="" />
                                   <p key={feature.id}><span className="purple-holder">{feature.feature_text}</span></p>
                               </div>
                            ))
                          }
                         
                        </div>
                        
                       
                      </div>
                      <div class="mb-4">  <Link data-ms-price-update="prc_enterprise-3e6g0lt7"  to="/sign-up-today" className="button-2 big w-inline-block">
                          <p>Get Started</p>
                        </Link></div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

const Feature = ({ text, isUnderlined = false }) => (
  <div className="check no-mb">
    <img
      loading="lazy"
      src="https://cdn.prod.website-files.com/648422913bdb661f6232a4c4/66ae54216e0ba0a6e1c25c4e_Group%201000002493.svg"
      alt=""
    />
    <p className={isUnderlined ? 'underline' : ''}>
      <span className="text-span-6">{text}</span>
    </p>
  </div>
);

export default PriceSection;
