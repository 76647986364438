import React, {useEffect, useState} from 'react';
import '../css/BlogPage.css';
import avatar1 from '../images/Furloop_Favicon.png';

import { Link } from 'react-router-dom';


const BlogPage = () => {
  
  const [articles, setArticles] = useState([]);
  useEffect(() => {
    fetch('https://cms.furloop.io/api/articles?populate=*')
      .then((response) => response.json())
      .then((data) => {
        console.log('fetched data:', data);
        setArticles(data.data);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);
  const pageStyle = {
    // backgroundImage: 'url(../images/Blog_Background.svg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
  };

   // Function to truncate title to a word limit
   const truncateText = (text, wordLimit) => {
    if (!text) return "";
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  return (

    

    <div style={pageStyle}>
      <div className='blog-bg'>

        <section className="main-section-header">
          <div className="main-container">
            <div className="content-wrap_center">
              <div id="about-us-content" className="content-wrap_center mw-800">
                <div className="subheader cta-color">Our Blog</div>
                <h1 className="h1 card-h1">Resources and insights</h1>
                <p className="paragraph">
                The latest industry news, interviews, technologies, and resources.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section>
            <div className='container'>
              <div  className='row'>
                
              {articles.map((article) => (
                <div className="col-md-4 mb-5" key={article.id}>
                  <div className="card blog-card">
                    {/* Cover Image */}
                    {article.cover?.formats?.thumbnail?.url ? (
                      <img
                        className="blog-hero-image"
                        src={`https://cms.furloop.io${article.cover.formats.thumbnail.url}`}
                        alt={article.attributes?.title || 'Default Title'}
                        sizes="90vw"
                        srcSet={`https://cms.furloop.io${article.cover.formats.thumbnail.url} 500w,
                                https://cms.furloop.io${article.cover.formats.thumbnail.url} 800w,
                                https://cms.furloop.io${article.cover.formats.thumbnail.url} 1080w,
                                https://cms.furloop.io${article.cover.formats.thumbnail.url} 1600w,
                                https://cms.furloop.io${article.cover.formats.thumbnail.url} 1920w`}
                        loading="lazy"
                      />
                    ) : (
                      <img
                        className="blog-hero-image"
                        src="path/to/default-image.jpg"
                        alt="Default cover"
                        loading="lazy"
                      />
                    )}

                    <div className="card-body">
                      {/* <p class="card-category"> 
                          
                      </p> */}
                    <h2 className="card-title" >
                      <Link to={`/blog/${article.slug}`} className="read-more">
                         {truncateText(article.title || 'Untitled', 5)}
                      </Link>
                    </h2>
                    {/* <p>
                      <Link to={`/blog/${article.slug}`} className="read-more">
                        {article.title || 'Untitled'}
                      </Link>
                    </p> */}
                      {/* <p className="card-title">{article.title || 'Untitled'}</p> */}
                      <p className="card-text-blog">
                      {truncateText(article.description || '', 20)}
                      </p>
                    </div>

                    <div className="media row my-4">
                      <div className="col-md-2 col-2">
                        {/* Author Image */}
                        <img
                          className="ml-3"
                          src={avatar1}
                          alt={article.author?.name || 'Unknown Author'}
                          sizes="90vw"
                          loading="lazy"
                        />
                      </div>
                      <div className="media-body col-md-6 col-6">
                        <h5 className="mt-0 card-text-writer">
                          {article.author?.name || 'Unknown Author'}
                        </h5>
                        <p className="card-date">
                           {article.createdAt ? new Date(article.createdAt).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }) : 'No Date'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
            </div>


        </section>




      </div>
    </div>
  );
};

export default BlogPage;
